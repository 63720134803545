import { callApi } from '../graphql'
import { getActivitiesPrice } from './activities'

export const getTemplatePrice = (templatePricing, selectedHotelIds, selectedTourId, selectedActivities) => {
    if (templatePricing && selectedHotelIds && selectedActivities) {
        const { hotels, tours, activities } = templatePricing
        const hotelPrice = hotels
            // Non alternative hotels are included
            .filter(({ productId, isAlternative }) => isAlternative && selectedHotelIds.includes(productId))
            .reduce((totalPrice, {
                name,
                price,
                endDate,
                startDate,
                productId,
            }) => {
                const originalRoom = hotels.find((room) => room.productId !== productId
                    && room.name === name
                    && room.endDate === endDate
                    && room.startDate === startDate)
                if (originalRoom) {
                    const priceDiff = price - originalRoom.price
                    return totalPrice + priceDiff
                }
                return totalPrice
            }, 0)
        const includedTour = tours.find(({ isAlternative }) => !isAlternative)
        const selectedTour = tours.find(({ productId }) => productId === selectedTourId)
        const tourPrice = includedTour && selectedTour && includedTour.productId !== selectedTour.productId
            ? -includedTour.price + selectedTour.price
            : 0
        const activitiesPrice = getActivitiesPrice(activities, selectedActivities)
        return templatePricing.price + hotelPrice + tourPrice + activitiesPrice
    }
    return null
}

export function getTemplateIdsFromComponent(component) {
    if (component?.component === 'BookTourButton' && !!component.tour?.content?.travelBoosterTemplateId) {
        return component.tour.content.travelBoosterTemplateId.split(',')
    }
    if (component?.component === 'BookBreakButton' && !!component.break?.content?.travelBoosterTemplateId) {
        const {
            isDynamicTemplate,
            isFreeChoiceTemplate,
        } = component.break.content
        if (!isDynamicTemplate || (isDynamicTemplate && !isFreeChoiceTemplate)) {
            return component.break.content.travelBoosterTemplateId.split(',')
        }
    }
    if (Array.isArray(component)) {
        return component.reduce((acc, item) => [...acc, ...getTemplateIdsFromComponent(item)], [])
    }
    if (!!component && typeof component === 'object') {
        return Object.keys(component).reduce((acc, key) => {
            const value = component[key]
            return [...acc, ...getTemplateIdsFromComponent(value)]
        }, [])
    }
    return []
}

export async function fetchTemplateAvailableDates(ids) {
    try {
        const { data } = await callApi(`
            query templateAvailableDates($ids: [ID]!) {
                templateAvailableDates(ids: $ids) {
                    endDate
                    startDate
                    productId
                }
            }
        `, { ids })
        return data.templateAvailableDates
    } catch {
        return null
    }
}

function addAvailableDatesToTemplate(content, availableDates) {
    const ids = content.travelBoosterTemplateId
    const relevantDates = availableDates.filter(({ productId }) => ids.includes(productId))
    if (relevantDates.length > 0) {
        content.availableDates = relevantDates
    }
}

export async function addAvailableDatesToTemplateButtons(component, availableDates) {
    if (component?.component === 'BookTourButton' && !!component?.tour) {
        addAvailableDatesToTemplate(component.tour.content, availableDates)
    } else if (component?.component === 'BookBreakButton' && !!component?.break) {
        addAvailableDatesToTemplate(component.break.content, availableDates)
    } else if (Array.isArray(component)) {
        component.forEach((item) => addAvailableDatesToTemplateButtons(item, availableDates))
    } else if (!!component && typeof component === 'object') {
        Object.keys(component).forEach((key) => {
            const value = component[key]
            addAvailableDatesToTemplateButtons(value, availableDates)
        })
    }
}
